<template>
  <div class="table_wrapper">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      item-key="id"
      :items-per-page="perPage"
      show-select
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Inbox</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                v-on:click="getDataFromApi(activeTab)"
              >
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </template>
            Refresh List
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:[`item.c3`]="{ item }">
        {{ item.c3.length > 50 ? item.c3.substring(0, 50) + "..." : item.c3 }}
        <v-btn
          small
          elevation="2"
          icon
          v-if="item.c3.length > 50"
          color="light-blue"
          dark
          v-on:click="showMessage(item.item.message)"
          ><v-icon small> mdi-eye</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              class="mx-1"
              fab
              small
              v-bind="attrs"
              v-on="on"
              elevation="2"
              disabled
              v-on:click="deleteItem(item.item.member_id)"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" persistent max-width="800">
      <v-card class="pt-5 pb-0">
        <v-card-text>{{ dialogMessageText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogMessage = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "Inbox",
  props: {
    member_id: {
      required: false,
    },
  },
  data() {
    return {
      selected: [],
      dialogMessage: false,
      dialogMessageText: "",
      dialog: false,
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "Name", value: "c1", sortable: false },
        { text: "Phone", value: "c2", sortable: false },
        { text: "Message", value: "c3", sortable: false },
        { text: "Subject", value: "c4", sortable: false },
        { text: "Created at", value: "c5", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = "https://gowawe.com/api/admin/selected-delete/bulkdelete";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      if (this.dialogAction.type == "delete") {
        this.desserts = this.desserts.filter((item, index) => {
          return this.dialogAction.data.indexOf(item.id) == -1;
        });
      }

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          if (
            this.dialogAction.type == "approve" ||
            this.dialogAction.type == "reject"
          ) {
            this.getDataFromApi(this.activeTab);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    getParse(getjson) {
      return JSON.parse(getjson).length > 0
        ? JSON.parse(getjson)[0]
        : JSON.parse(getjson);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.data.forEach((item, index) => {
          const key = {
            c1: item.name,
            c2: item.mobile,
            c3: item.message,
            c4: item.subject,
            c5: item.created_at,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.data.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    async getDesserts() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "inbox/filter",
          {
            page: this.page,
            per_page: this.perPage,
            api_token: this.token,
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    showMessage(message) {
      this.dialogMessage = true;
      this.dialogMessageText = message;
    },
    deleteItem(id) {
      this.actionLock = true;
      axios
        .get(process.env.VUE_APP_API_URL + "selected-delete/" + id)
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
